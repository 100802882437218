.App {
  text-align: center;
  margin: 20px;
  font-family: Arial, sans-serif;
}

.dropdown-container {
  margin: 20px 0;
}

.dropdown-label {
  font-size: 1.5em;
  margin-right: 10px;
}

.dropdown-select {
  font-size: 1.5em;
  padding: 5px 10px;
}

.scales {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.scale {
  flex: 1;
  margin: 0 10px;
}

.scale ul {
  list-style: none;
  padding: 0;
}

.scale li {
  font-size: 1.2em;
  margin: 5px 0;
}

.chords {
  margin-top: 20px;
}

table {
  margin: 0 auto;
  border-collapse: collapse;
  width: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
